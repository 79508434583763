import { useRef, useState } from 'react';
import './App.css';

function App() {
  const modelRef = useRef();
  const [info, setInfo] = useState();

  const menu = [
    {
      cameraTarget: "-1.4868517552260898m 0.9470590995624698m -3.2648508833865275m",
      cameraOrbit: "55deg 30deg 100%",
      fieldOfView: "5deg",
      info: "Оптоволоконный лазер Gweike LF3015L/2000 IPG это высокотехнологичный станок средней мощности, предназначенный для лазерной резки тонких металлических листов из нержавеющей, легированной, углеродистой и марганцевой стали, латуни, оцинкованных и алюминиевых листов, электролитической пластины и прочих материалов.",
      name: "Лазерный ЧПУ"
    },
    {
      cameraTarget: "3.2720363142554185m 1.2m -1.5376945652413743m",
      cameraOrbit: "200deg 60deg 100%",
      fieldOfView: "5deg",
      info: '\
        Технические характеристики<br>\
         * Тип робота – универсальный промышленный.<br>\
         * Полезная нагрузка – 16-30 кг.<br>\
         * Максимальный радиус действия – 2429-2952 мм.<br>\
         * Число управляемых осей – 6.<br>\
         * Тип конструкции – консольная, стандартная.<br>\
         * Способ монтажа – напольный, потолочный.<br>\
         * Класс защиты корпуса – IP64.<br>\
         * Класс защиты рабочей кисти – IP65.<br>\
      ',
      name: "Роботизированная рука"
    },
    {
      cameraTarget: "4.062315557127471m 2.6500000059604663m -6.311798996895981m",
      cameraOrbit: "270deg 40deg 100%",
      fieldOfView: "8deg",
      info: "\
        В зависимости от модели классификация подвесных кранов происходит по следующим параметрам:<br><br>\
        * грузоподъемность от 1 до 5 тонн;<br>\
        * высота подъема груза от 6 до 36 метров;<br>\
        * скорость движения 24-60 м/мин;<br>\
        * пролет: двухпролетные – 7,5-12 метров, однопролетные 3-15 метров.<br>\
      ",
      name: "Кран"
    }
  ]

  const handleClick = (event) => {
    const { clientX, clientY } = event;
    if (modelRef.current) {
      console.log(modelRef.current)
      let hit = modelRef.current.positionAndNormalFromPoint(clientX, clientY);
      console.log(hit)
    }
  };

  const itemClick = (item) => {
    if (modelRef.current) {
      console.log(item)
      const model = modelRef.current;
      model.cameraTarget = item.cameraTarget;
      model.cameraOrbit = item.cameraOrbit;
      modelRef.current.fieldOfView = item.fieldOfView;
      setInfo(item.info);
    }
  };

  return (
    <div className="App">
      <div className="model-viewer">
        <div className="side-menu-container">
          <div className="side-menu">
            <div className="side-menu__heading text-center">
              Управление
            </div>
            {menu.map((item) => {
              return (
                <div className="side-menu__item" onClick={() => itemClick(item)}>
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
        {info && (
          <div className="side-menu-container-right">
            <div className="side-menu">
              <div className="side-menu__heading text-center">
                Подробности
                <div className="close" onClick={() => setInfo(null)}>
                  X
                </div>
              </div>
              <div className="description" dangerouslySetInnerHTML={{ __html: info}}></div>
            </div>
          </div>
        )}
        <model-viewer
            src="/models/laser.glb"
            ios-src="/models/laser.usdz"
            alt="laser"
            min-camera-orbit="-Infinity 0deg auto"
            max-camera-orbit="Infinity 90deg auto"
            min-field-of-view="0deg"
            max-field-of-view="180deg"
            exposure="0.6"
            ar
            autoplay
            disable-tap
            camera-controls
            onClick={handleClick}
            ref={(ref) => {
              modelRef.current = ref;
            }}  
          />
      </div>
    </div>
  );
}

export default App;
